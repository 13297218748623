(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["nl-NL"] = {
            name: "nl-NL",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ".",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n%","n%"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "Euro",
                    abbr: "EUR",
                    pattern: ["$ -n","$ n"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "€"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["zondag","maandag","dinsdag","woensdag","donderdag","vrijdag","zaterdag"],
                        namesAbbr: ["zo","ma","di","wo","do","vr","za"],
                        namesShort: ["zo","ma","di","wo","do","vr","za"]
                    },
                    months: {
                        names: ["januari","februari","maart","april","mei","juni","juli","augustus","september","oktober","november","december"],
                        namesAbbr: ["jan","feb","mrt","apr","mei","jun","jul","aug","sep","okt","nov","dec"]
                    },
                    AM: [""],
                    PM: [""],
                    patterns: {
                        d: "d-M-yyyy",
                        D: "dddd d MMMM yyyy",
                        F: "dddd d MMMM yyyy HH:mm:ss",
                        g: "d-M-yyyy HH:mm",
                        G: "d-M-yyyy HH:mm:ss",
                        m: "d MMMM",
                        M: "d MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "HH:mm",
                        T: "HH:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": "-",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

}));
